.round-border {
  border-radius: 24.5px;
}

.phone {
  padding-left: 50px;
}

.react-tel-input .flag-dropdown {
  border-radius: 24px 0 0 24px !important;
}

.submit-button {
  font-weight: 900;
}
.submit-button:hover{
  color: #ffffff;
}
.submit-button:focus{
  color: #ffffff;
}
.social-button {
  height: 36px;
  line-height: 22px;
  width: 90px;
}
.social-button:hover{
  margin-top: -5px;
  transition: margin-top 0.5s;
}
.social-button:focus{
  margin-top: -5px;
  transition: margin-top 0.5s;
}
/* 
// .slick-dots li button {
//     font-size: 0;
//     width: 8px;
//     height: 6px;
//     background-color: #d8d8d8;
//     border: 0;
//     border-radius: 8px;
//     transition: 0.3s;
//     cursor: pointer;
//     outline: none;
// }

// .slick-dots {
//     position: relative;
//     bottom: 0;
// }
// .slick-dots li {
//     width: auto;
//     height: auto;
// }
// .slick-dots li button {
//     padding: 0;
// }
// .slick-dots li button:before {
//     display: none
// }

// .slick-dots {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     align-items: center;
//     margin-bottom: 0;
// }
// .slick-dots li {
//     margin: 0 5px;
//     text-align: center;
// }
// .slick-dots li button {
//     font-size: 0;
//     width: 8px;
//     height: 6px;
//     background-color: #d8d8d8;
//     border: 0;
//     border-radius: 8px;
//     -webkit-transition: 0.3s;
//     -o-transition: 0.3s;
//     transition: 0.3s;
//     cursor: pointer;
//     outline: none;
// }
// .slick-dots li.slick-active button {
//     width: 20px;
//     background-color: @base-color;
// }
// .slick-slide {
//     outline: none;
// }

// .cs-bg-slider,
// .cs-bg-item,
// .cs-bg-slider div {
//     height: 100%;
// }
// .cs-bg-slider .slick-dots {
//     position: absolute;
//     bottom: 40px;
//     right: 40px;
//     width: auto;
// }
// .cs-container .slick-slide,
// .cs-container .slick-list {
//     margin: 0;
// }
// .cs-bg-slider .slick-dots li {
//     margin-top: 0;
//     line-height: 1;
// } */


.cs-bg-slider .slick-dots li button {
    font-size: 0;
    width: 8px;
    height: 6px;
    background-color: #d8d8d8;
    border: 0;
    border-radius: 8px;
    transition: 0.3s;
    cursor: pointer;
    outline: none;
}

.cs-bg-slider .slick-dots {
    position: relative;
    bottom: 0;
}
.cs-bg-slider .slick-dots li {
    width: auto;
    height: auto;
}
.cs-bg-slider .slick-dots li button {
    padding: 0;
}
.cs-bg-slider .slick-dots li button:before {
    display: none
}

.cs-bg-slider .slick-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
}
.cs-bg-slider .slick-dots li {
    margin: 0 5px;
    text-align: center;
}
.cs-bg-slider .slick-dots li button {
    font-size: 0;
    width: 8px;
    height: 6px;
    background-color: #d8d8d8;
    border: 0;
    border-radius: 8px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    cursor: pointer;
    outline: none;
}
.cs-bg-slider .slick-dots li.slick-active button {
    width: 20px;
    background-color: #ffb900;
}
.cs-bg-slider .slick-slide {
    outline: none;
}

.cs-bg-slider,
.cs-bg-item,
.cs-bg-slider div {
    height: 100%;
}
.cs-bg-slider .slick-dots {
    position: absolute;
    bottom: 40px;
    right: 40px;
    width: auto;
}
.cs-container .slick-slide,
.cs-container .slick-list {
    margin: 0;
}
.cs-bg-slider .slick-dots li {
    margin-top: 0;
    line-height: 1;
}
@import './rsuite-styles.css';
/* // Style customization. */
@import './custom-theme.css';
@import './custom-form-theme.css';
@import './properties-card.css';
@import './tailwind.css';

/* // phone input */
@import '~react-phone-input-2/lib/style.css';

/* // slider */
@import '../../node_modules/slick-carousel/slick/slick.css';
@import '../../node_modules/slick-carousel/slick/slick-theme.css';
@import './slider.css';

/* //custom styling for Media step image upload */
div.rs-uploader div.rs-uploader-picture-text {
  display: flex;
  flex-direction: column;
  height: 1000px;
}

.rs-uploader-file-items {
  display: flex;
  flex-wrap: wrap;
}

.rs-uploader-trigger .rs-uploader-trigger-customize {
  display: flex;
  flex-direction: column;
}

.rs-uploader-picture-text .rs-uploader-file-item-content {
  padding: 0;
}

.rs-uploader-picture-text .rs-uploader-file-item-preview {
  position: relative;
  height: 100px;
  width: 100px;
}
.rs-uploader-picture-text .rs-uploader-file-item {
  padding: 0;
  height: 150px;
  width: 100px;
  margin-right: 15px;
  border: none;
}

/* .react-tel-input {



} */

.react-tel-input .form-control {
  border-color: #e5e5ea;
  border-radius: 8px;
}

.react-tel-input .flag-dropdown {
  border-color: #e5e5ea;
  border-radius: 8px 0 0 8px;
}
.react-tel-input .selected-flag {
  padding: 0 25%;
  border-radius: 8px 0 0 8px;
}
.react-tel-input .selected-flag:hover {
  background-color: #f5f5f5;
}
.react-tel-input .selected-flag:focus {
  background-color: #f5f5f5;
}
.animated.moveIn {
  animation: messageMoveIn 0.3s ease-in forwards;
}

html body #LeadboosterContainer {
  display: none !important;
}

html body #LeadboosterContainer #pipedrive-chat-holder {
  display: none !important;
}

.proactiveChat {
  display: none !important;
}
html body #LeadboosterContainer.proactiveChat {
  z-index: 0 !important;
}

/* //Investor page */
.hero-bg-gradient {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: auto;
  overflow: hidden;
  height: 70%;
  background-image: -webkit-gradient(linear, left top, right top, from(#ecb528), to(#ecb528));
  background-image: linear-gradient(90deg, #ecb528, #ecb528);
}
.hero-bg-gradient1 {
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  bottom: auto;
  overflow: hidden;
  height: 12vh;
  background-image: -webkit-gradient(linear, left top, right top, from(#ecb528), to(#ecb528));
  background-image: linear-gradient(90deg, #ecb528, #ecb528);
}

.wave-bottom {
  position: absolute;
  left: 0;
  top: auto;
  right: 0;
  bottom: -1px;
  z-index: 1;
  width: 100%;
  height: 4vw;
}
.circle-small {
  position: absolute;
  left: auto;
  top: 24%;
  right: -5%;
  bottom: auto;
  width: 8vw;
  opacity: 0.08;
  will-change: transform;
  transform: translate3d(0px, 5.04016%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}
.title.dark-bg {
  background-color: hsla(0, 0%, 100%, 0.1);
  color: #fff;
}
.bg-yellow-sl {
  background-color: #ecb528 !important;
}
.bg-def-sil {
  color: #575757;
  background: #f7f7fa !important;
}
.text-yellow-sl {
  color: #ecb528;
}
.btn-inline-flex {
  display: inline-flex;
  align-items: center;
}

/* bologsV1slider */

.next-arrow {
  position: absolute;
  right: -27px;
  top: 50%;
}

.pre-arrow {
  position: absolute;
  left: -27px;
  top: 50%;
}

.width-class {
  width: auto !important;
}

/* Room Slider Css */

.landlord_slider .slick-dots .slick-active button {
  background-color: black !important;
  height: 2px !important;
  padding: 1px 10px !important;
}

.landlord_slider .slick-dots li button {
  background-color: gray;
  height: 2px !important;
  padding: 1px 10px !important;
}

.landlord_slider .slick-dots li button:before {
  content: '' !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* Hide default react-responsive-grid resize handlers */
.react-resizable-handle {
  opacity: 0;
}

.react-grid-item.react-grid-placeholder {
  background-color: #A1C2D8 !important;
}